import React from "react";
import BlogFeed from "../components/blog-feed";
import ContactForm from "../components/contact-form";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ContactUs = ({ location }) => (
  <Layout>
    <Seo 
      pathName={location.pathname}
      title="Contact Us" 
      description="Reach out to 729 Solutions for a quote on your custom software development or design project. Engineers in the San Francisco Bay Area."
    />

    <div className="container py-6">
      <div className="row mb-5">
        <div className="col-md-4">
          <h1>Reach Out to Us...</h1>
          <p>
            We help you connect ALL the dots
            <br />
            <a
              href="tel:4159857073"
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="slack-kit-tooltip"
            >
              415-985-7073
            </a>
            <br />
            <a href="mailto:sales@729solutions.com">sales@729solutions.com</a>
          </p>

          <div className="pb-6">
            <a
              href="https://www.facebook.com/729Solutions"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="20 0 100 144"
                height="25"
                className="mr-1"
              >
                <path
                  fill="currentColor"
                  d="M89.69,34.27h12V15.35h-18a24.51,24.51,0,0,0-18.58,9.11s-4.92,4.65-5,18.25h0V56.9H42.28V77H60.1V128.9H80.68V77H98.37l2.48-20.09H80.67V42.71h0a8.26,8.26,0,0,1,8-8.47A8,8,0,0,1,89.69,34.27Z"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/729-solutions/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 144 144"
                height="25"
                className="mr-1"
              >
                <path
                  fill="currentColor"
                  d="M18.71,52.71H42.25v75.72H18.71ZM30.47,15.08A13.64,13.64,0,1,1,16.84,28.73,13.63,13.63,0,0,1,30.47,15.08"
                />
                <path
                  fill="currentColor"
                  d="M57,52.72H79.58V63.07h.32a24.73,24.73,0,0,1,22.27-12.23c23.84,0,28.22,15.68,28.22,36.07v41.51H106.87V91.61c0-8.79-.16-20.08-12.23-20.08-12.25,0-14.11,9.58-14.11,19.45v37.44H57Z"
                />
              </svg>
            </a>
            <a
              href="https://twitter.com/729solutions"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 144 144"
                height="25"
                className="mr-1"
              >
                <path
                  fill="currentColor"
                  d="M47.51,125.75c49.82,0,77.06-41.28,77.06-77.06,0-1.17,0-2.33-.07-3.5a55.07,55.07,0,0,0,13.5-14,54.17,54.17,0,0,1-15.55,4.26,27.08,27.08,0,0,0,11.91-15A54.28,54.28,0,0,1,117.14,27,27.1,27.1,0,0,0,71,51.71,76.9,76.9,0,0,1,15.18,23.4a27.12,27.12,0,0,0,8.4,36.17,26.94,26.94,0,0,1-12.27-3.38v.35A27.08,27.08,0,0,0,33.06,83.09a26.79,26.79,0,0,1-12.24.47,27.12,27.12,0,0,0,25.3,18.8A54.28,54.28,0,0,1,12.46,114,53.59,53.59,0,0,1,6,113.57a76.66,76.66,0,0,0,41.51,12.18"
                />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/channel/UC2VaNKIR0549LcM847-GDdQ/featured"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 144 130"
                height="25"
                className="mr-1"
              >
                <path
                  fill="currentColor"
                  d="M131.87,47.58a15.31,15.31,0,0,0-10.76-10.82c-9.49-2.56-47.52-2.56-47.52-2.56s-38,0-47.53,2.56A15.3,15.3,0,0,0,15.31,47.58c-2.55,9.55-2.55,29.47-2.55,29.47s0,19.92,2.55,29.47a15.26,15.26,0,0,0,10.75,10.82c9.49,2.56,47.53,2.56,47.53,2.56s38,0,47.52-2.56a15.28,15.28,0,0,0,10.76-10.82c2.54-9.55,2.54-29.47,2.54-29.47s0-19.92-2.54-29.47"
                />
                <path fill="#fff" d="M61.15,95.14,92.94,77.05,61.15,59Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="col-md-8">
          <h2>Or ask us to reach out to you.</h2>
          <ContactForm location={location} joinMailingList />
          <p>
            Email us at{" "}
            <a href="mailto:sales@729solutions.com">sales@729solutions.com</a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </p>
        </div>
      </div>
    </div>
    <BlogFeed title="Then While You Wait Patiently, Learn Something New." />
  </Layout>
);

export default ContactUs;
